import {useEffect, useRef, useState} from "react"
import {useGLTF, useScroll} from "@react-three/drei"
import {Euler, MathUtils, Vector3} from "three"
import {useFrame} from "@react-three/fiber"
import gsap from 'gsap'
import {useControls} from "leva"

export const Cube = ({config, texture, ...props}) => {
	//region Refs
	const group = useRef()
	//endregion

	//region States
	const scrollData = useScroll()

	const [isHovered, setIsHovered] = useState(false);
	const controls =  useControls({
		scaleMultiplier: {value: 1.2, min: 0, max: 10, step: .1},
	})

	//region Model
	const {nodes} = useGLTF("/3D/cube.glb")
	//endregion

	//region Handlers
	useEffect(() => {
		const groupWorldPosition = new Vector3()
		group.current.getWorldPosition(groupWorldPosition)

		group.current.children.forEach((mesh, index) => {
			mesh.originalPosition = mesh.position.clone()
			const meshWorldPosition = new Vector3()
			mesh.getWorldPosition(meshWorldPosition)

			mesh.directionVector = meshWorldPosition.clone().sub(groupWorldPosition).normalize()
			mesh.targetPosition = meshWorldPosition.clone().add(mesh.directionVector.clone().multiplyScalar(10))

			mesh.originalRotation = mesh.rotation.clone()
			mesh.targetRotation = new Euler(
					Math.random() * Math.PI * 2,
					Math.random() * Math.PI * 2,
					Math.random() * Math.PI * 2,
			)
		})
	}, [])
	//endregion

	//region Animation
	useFrame(() => {
		const showOrigin = scrollData.offset < 0.0001;

		/*Move broken glass elements*/
		group.current.children.forEach((mesh, index) => {
			/*Show or hide origin*/
			mesh.visible = mesh.name === 'origin' ? showOrigin : !showOrigin;

			// Hide the mesh
			if(!showOrigin && mesh.name === 'origin') {
				mesh.scale.set(0, 0, 0)
			} else if(showOrigin && mesh.name === 'origin' && mesh.scale.x === 0){ // Show the origin mesh when it's supposed to be shown but his scale is 0
				mesh.scale.set(1, 1, 1);
			}

			mesh.position.x = MathUtils.lerp(mesh.originalPosition.x, mesh.targetPosition.x, scrollData.offset)
			mesh.position.y = MathUtils.lerp(mesh.originalPosition.y, mesh.targetPosition.y, scrollData.offset)
			mesh.position.z = MathUtils.lerp(mesh.originalPosition.z, mesh.targetPosition.z, scrollData.offset)

			mesh.rotation.x = MathUtils.lerp(mesh.originalRotation.x, mesh.targetRotation.x, scrollData.offset)
			mesh.rotation.y = MathUtils.lerp(mesh.originalRotation.y, mesh.targetRotation.y, scrollData.offset)
			mesh.rotation.z = MathUtils.lerp(mesh.originalRotation.z, mesh.targetRotation.z, scrollData.offset)
		})
	})
	//endregion

	return (
			<group {...props} dispose={null}
			       ref={group}
			       onPointerOver={(e) => {
				       setIsHovered(true)

				       gsap.to(e.object.scale, {
					       x: controls.scaleMultiplier,
					       y: controls.scaleMultiplier,
					       z: controls.scaleMultiplier,
					       duration: .3,
				       })
			       }}
			       onPointerOut={(e) => {
				       setIsHovered(false)

				       gsap.to(e.object.scale, {
					       x: 1,
					       y: 1,
					       z: 1,
					       duration: .3,
				       })
			       }}
			>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.origin.geometry}
						material={nodes.origin.material}
						name={'origin'}
						visible={false}
				>
					<meshPhysicalMaterial
							{...config}
							envMap={texture}
					/>
					{/*<meshStandardMaterial roughness={0} metalness={1} envMap={texture}/>*/}
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Parts.geometry}
						material={nodes.Parts.material}
						position={[-1.641, 3.168, -3.501]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell001.geometry}
						material={nodes.Cube_cell001.material}
						position={[2.047, -3.608, 2.774]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell002.geometry}
						material={nodes.Cube_cell002.material}
						position={[3.747, -3.866, -3.78]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell003.geometry}
						material={nodes.Cube_cell003.material}
						position={[-3.826, 3.797, 3.807]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell004.geometry}
						material={nodes.Cube_cell004.material}
						position={[-3.907, 2.397, 3.761]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell005.geometry}
						material={nodes.Cube_cell005.material}
						position={[3.796, -3.835, -3.77]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell006.geometry}
						material={nodes.Cube_cell006.material}
						position={[-3.681, -3.083, -3.016]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell007.geometry}
						material={nodes.Cube_cell007.material}
						position={[-2.072, -2.619, -3.258]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell008.geometry}
						material={nodes.Cube_cell008.material}
						position={[-3.881, 3.722, 3.808]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell009.geometry}
						material={nodes.Cube_cell009.material}
						position={[-3.301, -3.807, 2.252]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell010.geometry}
						material={nodes.Cube_cell010.material}
						position={[3.808, -3.886, 3.647]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell011.geometry}
						material={nodes.Cube_cell011.material}
						position={[-3.783, 3.82, 3.777]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell012.geometry}
						material={nodes.Cube_cell012.material}
						position={[3.768, -3.754, 3.866]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell013.geometry}
						material={nodes.Cube_cell013.material}
						position={[1.44, -3.582, -3.001]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell014.geometry}
						material={nodes.Cube_cell014.material}
						position={[-3.804, 3.843, -3.757]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell015.geometry}
						material={nodes.Cube_cell015.material}
						position={[3.753, 3.777, -3.867]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell016.geometry}
						material={nodes.Cube_cell016.material}
						position={[3.306, 3.711, -2.022]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell017.geometry}
						material={nodes.Cube_cell017.material}
						position={[-2.941, 3.67, 1.579]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell018.geometry}
						material={nodes.Cube_cell018.material}
						position={[3.806, -2.52, 3.786]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell019.geometry}
						material={nodes.Cube_cell019.material}
						position={[3.825, -3.72, -3.854]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell020.geometry}
						material={nodes.Cube_cell020.material}
						position={[-2.091, -3.169, 3.585]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell021.geometry}
						material={nodes.Cube_cell021.material}
						position={[3.795, 3.795, -3.804]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell022.geometry}
						material={nodes.Cube_cell022.material}
						position={[-3.805, -3.683, 3.892]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell023.geometry}
						material={nodes.Cube_cell023.material}
						position={[-3.225, 3.392, 3.896]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell024.geometry}
						material={nodes.Cube_cell024.material}
						position={[2.741, 3.892, -3.775]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell025.geometry}
						material={nodes.Cube_cell025.material}
						position={[3.69, 2.72, 3.93]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell026.geometry}
						material={nodes.Cube_cell026.material}
						position={[-3.875, 3.803, 3.734]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell027.geometry}
						material={nodes.Cube_cell027.material}
						position={[-3.855, 3.677, -3.857]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell028.geometry}
						material={nodes.Cube_cell028.material}
						position={[-2.053, -1.528, 1.517]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell029.geometry}
						material={nodes.Cube_cell029.material}
						position={[-3.9, -3.739, 3.668]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell030.geometry}
						material={nodes.Cube_cell030.material}
						position={[3.851, -3.791, -3.766]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell031.geometry}
						material={nodes.Cube_cell031.material}
						position={[1.175, 1.126, 2.602]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell032.geometry}
						material={nodes.Cube_cell032.material}
						position={[-2.774, -3.29, -1.753]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell033.geometry}
						material={nodes.Cube_cell033.material}
						position={[-3.921, 3.661, -3.718]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell034.geometry}
						material={nodes.Cube_cell034.material}
						position={[3.843, -3.843, -2.269]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell035.geometry}
						material={nodes.Cube_cell035.material}
						position={[3.894, -3.75, -3.705]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell036.geometry}
						material={nodes.Cube_cell036.material}
						position={[-3.802, 3.747, 3.875]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell037.geometry}
						material={nodes.Cube_cell037.material}
						position={[3.205, 1.967, -2.285]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell038.geometry}
						material={nodes.Cube_cell038.material}
						position={[-3.653, -3.845, -3.848]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell039.geometry}
						material={nodes.Cube_cell039.material}
						position={[3.81, -3.582, 3.895]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell040.geometry}
						material={nodes.Cube_cell040.material}
						position={[-3.774, -3.89, 3.715]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell041.geometry}
						material={nodes.Cube_cell041.material}
						position={[-3.912, -3.636, -3.788]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell042.geometry}
						material={nodes.Cube_cell042.material}
						position={[-3.714, 3.834, -2.315]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell043.geometry}
						material={nodes.Cube_cell043.material}
						position={[3.813, -2.189, -3.586]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell044.geometry}
						material={nodes.Cube_cell044.material}
						position={[-3.749, 3.818, -3.849]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell045.geometry}
						material={nodes.Cube_cell045.material}
						position={[-3.914, -3.692, 3.674]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell046.geometry}
						material={nodes.Cube_cell046.material}
						position={[-3.739, 3.848, 3.814]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell047.geometry}
						material={nodes.Cube_cell047.material}
						position={[3.651, 3.843, 2.287]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell048.geometry}
						material={nodes.Cube_cell048.material}
						position={[-3.881, -3.723, 3.808]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell049.geometry}
						material={nodes.Cube_cell049.material}
						position={[-2.581, 1.561, -1.49]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell050.geometry}
						material={nodes.Cube_cell050.material}
						position={[-3.589, 3.935, 3.671]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell051.geometry}
						material={nodes.Cube_cell051.material}
						position={[-2.548, 1.843, 1.911]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell052.geometry}
						material={nodes.Cube_cell052.material}
						position={[1.833, -1.777, -2.761]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell053.geometry}
						material={nodes.Cube_cell053.material}
						position={[-3.821, -3.721, -3.848]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell054.geometry}
						material={nodes.Cube_cell054.material}
						position={[-2.898, 1.664, 3.649]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell055.geometry}
						material={nodes.Cube_cell055.material}
						position={[-3.906, 3.755, 2.477]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell056.geometry}
						material={nodes.Cube_cell056.material}
						position={[-3.748, -3.828, -3.845]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell057.geometry}
						material={nodes.Cube_cell057.material}
						position={[3.784, 3.673, -3.871]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell058.geometry}
						material={nodes.Cube_cell058.material}
						position={[-3.815, 3.84, 3.672]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell059.geometry}
						material={nodes.Cube_cell059.material}
						position={[-3.719, -3.872, 3.782]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell060.geometry}
						material={nodes.Cube_cell060.material}
						position={[-3.745, 3.724, -3.915]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell061.geometry}
						material={nodes.Cube_cell061.material}
						position={[3.297, 2.06, -3.778]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell062.geometry}
						material={nodes.Cube_cell062.material}
						position={[-3.857, -3.806, -3.746]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell063.geometry}
						material={nodes.Cube_cell063.material}
						position={[-3.624, -2.129, -3.676]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell064.geometry}
						material={nodes.Cube_cell064.material}
						position={[3.817, 3.746, 3.865]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell065.geometry}
						material={nodes.Cube_cell065.material}
						position={[3.689, -3.907, -3.769]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell066.geometry}
						material={nodes.Cube_cell066.material}
						position={[-3.581, 3.892, -3.814]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell067.geometry}
						material={nodes.Cube_cell067.material}
						position={[2.853, -3.446, -1.706]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell068.geometry}
						material={nodes.Cube_cell068.material}
						position={[-3.054, 2.011, -3.625]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell069.geometry}
						material={nodes.Cube_cell069.material}
						position={[-3.665, -2.255, 3.817]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell070.geometry}
						material={nodes.Cube_cell070.material}
						position={[2.048, 3.161, -3.67]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell071.geometry}
						material={nodes.Cube_cell071.material}
						position={[3.535, -2.768, -1.782]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell072.geometry}
						material={nodes.Cube_cell072.material}
						position={[-3.738, -3.734, -3.916]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell073.geometry}
						material={nodes.Cube_cell073.material}
						position={[1.708, 3.034, -2.135]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell074.geometry}
						material={nodes.Cube_cell074.material}
						position={[-3.783, -3.857, -3.746]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell075.geometry}
						material={nodes.Cube_cell075.material}
						position={[3.742, 3.878, 3.807]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell076.geometry}
						material={nodes.Cube_cell076.material}
						position={[-2.589, 3.586, -2.522]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell077.geometry}
						material={nodes.Cube_cell077.material}
						position={[2.779, -3.812, 3.872]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell078.geometry}
						material={nodes.Cube_cell078.material}
						position={[2.818, 3.736, 3.919]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell079.geometry}
						material={nodes.Cube_cell079.material}
						position={[-3.924, -2.151, 3.672]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell080.geometry}
						material={nodes.Cube_cell080.material}
						position={[3.178, -2.411, 1.591]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell081.geometry}
						material={nodes.Cube_cell081.material}
						position={[3.841, 3.701, -2.03]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell082.geometry}
						material={nodes.Cube_cell082.material}
						position={[1.982, 3.274, 2.255]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell083.geometry}
						material={nodes.Cube_cell083.material}
						position={[2.756, -3.818, -3.858]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell084.geometry}
						material={nodes.Cube_cell084.material}
						position={[3.782, -3.827, 3.803]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell085.geometry}
						material={nodes.Cube_cell085.material}
						position={[2.498, 3.839, 3.855]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell086.geometry}
						material={nodes.Cube_cell086.material}
						position={[-3.928, 3.704, 3.704]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell087.geometry}
						material={nodes.Cube_cell087.material}
						position={[-3.798, 3.779, -3.804]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell088.geometry}
						material={nodes.Cube_cell088.material}
						position={[3.849, 2.254, 3.642]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell089.geometry}
						material={nodes.Cube_cell089.material}
						position={[-3.634, 3.909, 3.567]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell090.geometry}
						material={nodes.Cube_cell090.material}
						position={[-1.716, 3.403, 3.398]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell091.geometry}
						material={nodes.Cube_cell091.material}
						position={[-3.648, -3.933, -3.708]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell092.geometry}
						material={nodes.Cube_cell092.material}
						position={[3.845, 3.845, -3.731]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell093.geometry}
						material={nodes.Cube_cell093.material}
						position={[3.875, 3.782, 3.747]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell094.geometry}
						material={nodes.Cube_cell094.material}
						position={[2.317, -2.549, 3.545]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell095.geometry}
						material={nodes.Cube_cell095.material}
						position={[3.865, 3.812, 2.314]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell096.geometry}
						material={nodes.Cube_cell096.material}
						position={[-2.05, -3.819, -3.739]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell097.geometry}
						material={nodes.Cube_cell097.material}
						position={[3.802, -2.906, -3.097]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell098.geometry}
						material={nodes.Cube_cell098.material}
						position={[3.565, 2.859, 2.157]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
				<mesh
						castShadow={true}
						receiveShadow={true}
						geometry={nodes.Cube_cell099.geometry}
						material={nodes.Cube_cell099.material}
						position={[-3.632, 3.814, -3.894]}
				>
					<meshPhysicalMaterial
							color={config.bg} {...config}
							envMap={texture}
					/>
				</mesh>
			</group>
	)
}
