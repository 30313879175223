import React, {createContext, useContext, useMemo} from 'react'
import ReactDOM from 'react-dom/client'
import './styles/globals.sass'
import App from './App'
import reportWebVitals from './reportWebVitals'

//region App context
const AppContext = createContext({})
export const AppProvider = ({children}) => {
	const colors = useMemo(() => {
		return {
			dark: '#080A1A',
			primary: '#0B193E',
			secondary: '#DDE7F2',
			hover: '#7AB8EA',
			action: '#2B75ED',
			pink: '#F95FC2',
			orange: '#F2654E',
		}
	}, [])

	const gradients = useMemo(() => {
		return {
			gradient: [colors.secondary, colors.hover, colors.action, '#0036AF', colors.primary],
			gradient_stops: [.016, .204, .444, .718, .966],
			gradient_pink_orange_blue: [colors.pink, colors.orange, colors.secondary, colors.hover, colors.action],
			gradient_pink_orange_blue_stops: [0, .238, .473, .698, 1],
		}
	}, [colors])

	const lightColors = useMemo(() => {
		return {
			lightBlue: '#7AB8EA',
			blue: '#2B75ED',
			pink: '#F95FC2',
			orange: '#F2654E',
		}
	},[])

	return (<AppContext.Provider
			value={{
				colors,
				gradients,
				lightColors,
			}}
	>
		{children}
	</AppContext.Provider>)
}

export const useAppContext = () => {
	return useContext(AppContext)
}
//endregion

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
		<React.StrictMode>
			<AppProvider>
				<App/>
			</AppProvider>
		</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
