import {MainCanvas} from "./3D/MainCanvas";

function App() {
	return (
			<>
				<MainCanvas/>
			</>
	);
}

export default App;
