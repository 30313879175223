// Style
import styles from './MainCanvas.module.sass'

// Default
import {Leva, useControls} from 'leva'
import {useRef} from "react"

// Three
import * as THREE from "three"
import {Canvas, useFrame} from "@react-three/fiber"
import {Perf} from "r3f-perf"
import {Box, CubeCamera, OrbitControls, PerspectiveCamera, ScrollControls} from "@react-three/drei"
import {ZSparkles} from "./ZSparkles"
import {useAppContext} from "../index"
import {Cube} from "./Cube"
// Post-processing

export const MainCanvas = () => {
	//region Context
	const {colors} = useAppContext()
	//endregion

	return (
			<>
				<Canvas
						className={styles.canvas}
						flat
				>
					<color attach="background"
					       args={[colors.dark]}
					/>

					<Perf position={'top-left'}/>


					{/*Scroll controls*/}
					<ScrollControls pages={4}>
						<CanvasContent/>
					</ScrollControls>
				</Canvas>
				<Leva collapsed={true}
				      hidden={process.env.NODE_ENV === 'production'}/>
			</>
	)
}

const CanvasContent = () => {
	//region Refs
	const cameraRef = useRef()
	//endregion

	//region Controls
	const config = useControls({
		// transmission: {value: .99, min: 0, max: 1},
		roughness: {value: .1, min: 0, max: 1, step: 0.01},
		metalness: {value: 1, min: 0, max: 1, step: 0.01},
		thickness: {value: .5, min: 0, max: 10, step: 0.01},
		ior: {value: 1.2, min: 0, max: 10, step: 0.01},

		cameraX: {value: 20, min: 0, max: 100, step: 1},
		cameraY: {value: 20, min: 0, max: 100, step: 1},
	})
	//endregion

	//region Animation
	// Make the camera move
	useFrame((state) => {
		cameraRef.current.rotation.y = THREE.MathUtils.lerp(cameraRef.current.rotation.y, (-state.mouse.x * Math.PI) / config.cameraY, 0.05)
		cameraRef.current.rotation.x = THREE.MathUtils.lerp(cameraRef.current.rotation.x, (state.mouse.y * Math.PI) / config.cameraX, 0.05)
	})
//endregion

	return (<>
		{/*Camera controls*/}
		<PerspectiveCamera
				ref={cameraRef}
				fov={75}
				near={1}
				far={80}
				position={[0, 0, 20]}
				makeDefault
		/>
		{/*<OrbitControls/>*/}

		<ZSparkles distance={100}/>

		<CubeCamera
				resolution={2 ** 10}
				near={1}
				far={80}
		>
			{(texture) => (
					<>
						<Cube
								config={config}
								texture={texture}
						/>
					</>
			)}
		</CubeCamera>
	</>)
}
